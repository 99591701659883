import React from "react";

function Unreleased() {
    return (
        <div>
            <br/>
<h2>Unreleased Pins</h2>
<hr/>
<h3><img src="/images/Other15/pin_triad_wars.png" align="right"/>Triad Wars</h3>
<p><b>Status</b>: Not officially released at a PAX, some distributed online<br/>
A small number of these were rewards for a community contest in 2015. Around 60 of these were sold on eBay for charity and the rest were sold as a lot in a liquidation auction.</p>
<br clear="all"/>
<hr/>

<h3><img src="/images/Other20/pin_radical_rabbit_stew.png" align="right"/>Radical Rabbit Stew</h3>
<p><b>Status</b>: Not officially released at a PAX, some distributed online<br/>
These were given away online in 2020 to a small number of people that participated in a speed run contest for Radical Rabbit Stew. Some were also included in DSBs.</p>
<br clear="all"/>
<hr/>

<h3><img src="/images/Other19/pin_population_one.png" align="right"/>Population One</h3>
<p><b>Status</b>: Not officially released at a PAX<br/>
A small number of these were accidentally traded away at PAX West 2019.</p>
<br clear="all"/>
<hr/>
{/*
<h3><img src="/images/site/cant-catch-harry.png" align="right" width="200"/>Can’t Catch Harry </h3>
<p><b>Status</b>: Not officially released at a PAX<br/>
The existence of this pin was first discovered at PAX Unplugged 2023, when it was shown by an employee of Breaking Games. All copies of this pin are in the possession of Breaking Games or Penny Arcade.</p>
<br clear="all"/>
<hr/>

<h3><img src="/images/site/cyberpunk.png" align="right" width="200"/>Cyberpunk Samurai</h3>
<p><b>Status</b>: Not officially released at a PAX<br/>
It is believed that 2 of these were accidentally sold, and are now in the hands of collectors. </p>
<br clear="all"/>
<hr/>
    */}
<h3><img src="/images/site/moonrise.png" align="right" width="200"/>Moonrise Part 3</h3>
<p><b>Status</b>: Never manufactured<br/>
In 2015, Undead Labs planned a series of 3 pins for the game Moonrise that would fit together. Emberjaw was released at PAX South 2015, Nemony was released at PAX East 2015, and the third one was never made.</p>
<br clear="all"/>
<hr/>

<h3><img src="/images/site/magic-legends.png" align="right" width="200"/>Magic Legends Geomancer</h3>
<p><b>Status</b>: Never manufactured<br/>
This was initially on the East 2020 pin quest, but it was never actually made, to my knowledge.</p>
<br clear="all"/>
<hr/>

<h3><img src="/images/site/checkpoint.png" align="right" width="400"/>Checkpoint Buttons</h3>
<p><b>Status</b>: Never manufactured<br/>
In 2020, Checkpoint was planning to make a set of four pins, one to be released at each PAX. Only the A button was officially released at a PAX (South 2020). The B Button was manufactured, but not in time to be sold at PAX East. People were able to preorder the B Button, but it took a long time for them to get to people. The Arrow and Start Buttons were never made.</p>
<br clear="all"/>
<hr/>

<h3><img src="/images/site/aus23panel-unreleased.png" align="right" width="400"/>Other</h3>
<p><b>Status</b>: Never manufactured<br/>
Several unreleased pins were revealed by Kiko at the PAX Aus 23 Pinny Pals panel.</p>
<br clear="all"/>
<hr/>


        </div>
    );
}

export default Unreleased;